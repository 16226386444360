import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { dateFormat } from '../../helpers';
import formatUTCHours from '../../helpers/format-utc-hours';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.neutrals.low.medium,
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
  },
  avatarNumber: {
    width: 60,
    height: 60,
    fontSize: 25,
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
  },
  scheduleTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  scheduleDate: {
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  between: {
    width: '16%',
    marginTop: 24,
    marginRight: -12,
    marginLeft: -12,
  },
}));

export function ChallengeSchedule({ challenge }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { startDate, dueDate, resultDate } = challenge;
  const language = useSelector((state) => state.language);

  return (
    <Grid item xs={12}>
      <Typography className={classes.title}>CRONOGRAMA</Typography>
      <Box display="flex" justifyContent="center" marginTop="20">
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Avatar className={classes.avatarNumber}>1º</Avatar>
          <Typography className={classes.scheduleTitle}>{t('inscricoes')}</Typography>
          <Typography className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(startDate), language)}
          </Typography>
        </Box>
        <Box className={classes.between}>
          <hr style={{ border: '1px solid theme.palette.primary.main' }} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Avatar className={classes.avatarNumber}>2º</Avatar>
          <Typography className={classes.scheduleTitle}>{t('selecao')}</Typography>
          <Typography className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(dueDate), language)}
          </Typography>
        </Box>
        <Box className={classes.between}>
          <hr style={{ border: `${'1px solid theme.palette.primary.main'}` }} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Avatar className={classes.avatarNumber}>3º</Avatar>
          <Typography className={classes.scheduleTitle}>{t('resultado')}</Typography>
          <Typography className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(resultDate), language)}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}