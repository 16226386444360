import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';
import { authHeader } from '../helpers/auth-headers';

export const ideaService = {
  getIdeasUser,
  sendIdeaImagine,
  drafIdeaImagine,
  uploadFiles,
  getIdeaDetails,
  deleteDraft,
  getDraft,
  getIdeasByUserId,
  sendApproved,
  sendDelegate,
  getPendingIdeas,
  getStatusIdeas,
  sendTimelineView,
  updateIdea,
  removeFiles
};

function getIdeasUser(page, sort, query = '', limit = 10, statusCode=[]) {
  if (statusCode.length === 0) statusCode = ["CONCLUDED","CANCELED","AWAITING_QUALIFICATION","UNQUALIFIED","PATENT_PENDING","EXECUTION_QUEUE","EXECUTING","QUALIFIED","DRAFT","PENDING_REVIEW"]
  return axios
    .get(
      `${
        config.URL_BASE
      }/ideas/user?q=${query}&page=${page}&sort=${JSON.stringify(
        sort
      )}&quantityPerPage=${limit}&statusCode=${JSON.stringify(statusCode)}`,
      requestHeaders()
    )
    .then(handleResponse);
}

function sendIdeaImagine(idea) {
  return axios
    .post(`${config.URL_BASE}/ideas`, idea, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}

function drafIdeaImagine(idea) {
  return axios
    .post(`${config.URL_BASE}/ideas/draft`, idea, requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
}

function uploadFiles(files, ideaId) {
  const promises = files.map((file) => {
    const data = new FormData();

    data.append('file', file.file, file.name);

    return axios.post(`${config.URL_BASE}/uploads/idea/${ideaId}`, data, {
      headers: authHeader(),
    });
  });

  return Promise.all(promises);
}

function getIdeaDetails(id) {
  return axios
    .get(`${config.URL_BASE}/ideas/${id}`, requestHeaders())
    .then((response) => response.data.data);
}

function deleteDraft(id) {
  return axios.delete(`${config.URL_BASE}/ideas/${id}`, requestHeaders());
}

function getDraft(id) {
  return axios
    .get(`${config.URL_BASE}/ideas/${id}`, requestHeaders())
    .then((response) => response.data.data);
}

function getIdeasByUserId(id, page, limit) {
  return axios
    .get(
      `${config.URL_BASE}/ideas/timeline/${id}?page=${page}&limit=${limit}`,
      requestHeaders()
    )
    .then(handleResponse)
    .then((response) => response);
}

function sendApproved(approved) {
  let qualified =!approved.approved?'unqualified':'approved';
  return axios
    .put(
      `${config.URL_BASE}/ideas/${approved._id}/${qualified}`,
      approved,
      requestHeaders()
    )
      .then(handleResponse);
}

function sendDelegate(delegate) {
  return axios
    .put(
      `${config.URL_BASE}/ideas/${delegate._id}/delegate`,
      delegate,
      requestHeaders()
    )
    .then(handleResponse);
}

function getPendingIdeas() {
  return axios
    .get(`${config.URL_BASE}/notification/pending-ideas`, requestHeaders())
    .then(handleResponse);
}

function getStatusIdeas() {
  return axios
    .get(`${config.URL_BASE}/notification/status-ideas`, requestHeaders())
    .then(handleResponse);
}

function sendTimelineView(id, status) {
  return axios
    .put(`${config.URL_BASE}/ideas/${id}/timeline`, { 
      timeline: status
    }, requestHeaders())
    .then(handleResponse);
}

function updateIdea(id, idea) {
  const ideaToPut = [idea].map(({attachments, ...rest}) => rest);

  return axios
    .put(`${config.URL_BASE}/ideas/${id}`, ideaToPut[0], requestHeaders())
    .then(handleResponse)
    .then((response) => response.data);
  }

function removeFiles(ideaId, fileIds) {
  const files = fileIds.filter(fileId => fileId.length > 20);

  return axios
    .put(`${config.URL_BASE}/uploads/detach/idea/${ideaId}`, { attachmentIds: files }, requestHeaders())
    .then(handleResponse)
  }