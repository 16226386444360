import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Card, CardContent, makeStyles, useTheme, Typography } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { dateFormat } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(6, 4),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 0,
    '&:last-child': {
      padding: 0,
    }
  },
  containerInfo: {
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    color: theme.palette.neutrals.low.medium,
  },
  name: {
    color: theme.palette.neutrals.low.main,  
  },
}));

function ChallengeFollowUp({ dueDate, daysLeft, ups, ideas, comments, user, area }) {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={4}>
        <Card elevation={0} className={classes.card}>
          {daysLeft >= 1 ? (
            <CardContent className={classes.content}>
              <Box>
                <WatchLaterIcon
                  fontSize="large"
                  style={{ color: theme.palette.primary.main }}
                />
              </Box>
              <Typography variant="h1" style={{ color: theme.palette.primary.main }}>
                {daysLeft} {t('dia')}{daysLeft > 1 && 's'}
              </Typography>
              <Typography variant="caption" className={classes.description}>
                {t('restantes_para_o_fim_do_desafio')}
              </Typography>
            </CardContent>
          ) : (
            <CardContent className={classes.content}>
              <Box>
                <WatchLaterIcon
                  fontSize="large"
                  style={{ color: theme.palette.neutrals.low.medium }}
                />
              </Box>
              <Typography variant="h2">
                {t('fase_encerrada')}
              </Typography>
              <Typography variant="caption" className={classes.description}>
                no dia {dateFormat(dueDate)}
              </Typography>
            </CardContent>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card elevation={0} className={classes.card}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={4} lg={2} className={classes.containerInfo}>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <Box style={{ color: theme.palette.auxiliary.pink.main }}>
                    <EmojiObjectsIcon fontSize="large" />
                    <Typography variant="h1" align="center">
                      {ideas || 0}
                    </Typography>
                  </Box>
                  <Typography variant="caption" className={classes.description}>
                    {t('ideias').toLowerCase()}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} lg={2} className={classes.containerInfo}>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <Box style={{ color: theme.palette.auxiliary.blue.main }}>
                    <ThumbUpIcon fontSize="large" />
                    <Typography variant="h1" align="center">
                      {ups || 0}
                    </Typography>
                  </Box>
                  <Typography variant="caption" className={classes.description}>
                    {t('ups')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} lg={2} className={classes.containerInfo}>
                <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                  <Box style={{ color: theme.palette.auxiliary.orange.main }}>
                    <ChatBubbleIcon fontSize="large" />
                    <Typography variant="h1" align="center">
                      {comments || 0}
                    </Typography>
                  </Box>
                  <Typography variant="caption" className={classes.description}>
                    {t('comentarios')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} className={classes.containerInfo}>
                <Box>
                  <Box>
                    <Typography variant="caption" className={classes.description}>
                      {t('area_que_mais_contribuiu')}
                    </Typography>
                    <Typography variant="h3" className={classes.name}>
                      {area}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" className={classes.description}>
                      {t('colaborador_que_mais_contribuiu')}
                    </Typography>
                    <Typography variant="h3" className={classes.name}>
                      {user}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export { ChallengeFollowUp };
