import React from 'react'
import {
  Grid,
  Typography,
  Paper,
  Box,
  makeStyles,
  Button
} from "@material-ui/core"
import { TagPerfil } from "./../../components"
import { useSelector } from "react-redux";
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  containerTitleWrapper: {
    display: "flex",
    alignItems: "center"
  },
  containerTitle: {
    fontWeight: 600
  },
  titleIcon: {
    width: 40,
    marginRight: 16,
    height: 40,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  card: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "2rem",
    boxShadow: "none",
  },
  boldText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  normalText: {
    fontSize: 14,
  },
  listItem: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #E7EAED",
    }
  }
}));

export const ListFunction = ({ toggleEdit }) => {
  const classes = useStyles();
  const {
    selectedProfileType,
    selectedUser,
    selectedBusiness
  } = useSelector(state => state.userFunction)
  const { t } = useTranslation();

  return (
    <Paper className={classes.card}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8} className={classes.containerTitleWrapper}>
              <div className={classes.titleIcon}>
                <DescriptionIcon color={"primary"} />
              </div>
              <Typography variant="h5" component="h1" className={classes.containerTitle}>{t('funcao_do_usuario')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end" height="100%" width="100%">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<EditIcon />}
                  onClick={toggleEdit}
                >
                  Editar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Typography variant="h5" component="h1" className={classes.boldText}>perfil do usuário:</Typography>
            &nbsp;&nbsp;
            <TagPerfil perfil={selectedProfileType} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h5" component="h1" className={classes.boldText}>unidade de negócio</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" component="h1" className={classes.boldText}>local</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" component="h1" className={classes.boldText}>área de atuação</Typography>
                </Grid>
              </Grid>
            </Grid>
            { selectedBusiness.map(item => (
              <Grid item xs={12} className={classes.listItem}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="h5" component="h1" className={classes.normalText}>{ item.businessUnit.name }</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" component="h1" className={classes.normalText}>{ item.local.name }</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h5" component="h1" className={classes.normalText}>{ item.occupationArea.name }</Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
