import axios from 'axios';
import config from '../config';
import { handleResponse, requestHeaders } from '../helpers';

export const faqService = {
  getAllQuestions,
  getQuestionById,
  createQuestion,
  editQuestion,
  deleteQuestion,
};

function getAllQuestions(page, sort, limit) {
  const url = `${config.URL_BASE
  }/faq/questions?page=${page}&sort=${JSON.stringify(sort)}&limit=${limit}`;

  return axios.get(url, requestHeaders()).then(handleResponse);
}

function getQuestionById(id){
  return axios.get(`${config.URL_BASE}/faq/questions/${id}`, requestHeaders())
    .then(handleResponse);
}

function createQuestion(){
  return axios.post(`${config.URL_BASE}/faq/questions`, requestHeaders())
    .then(handleResponse);
}

function editQuestion(id){
  return axios.put(`${config.URL_BASE}/faq/questions/${id}`, requestHeaders())
    .then(handleResponse);
}

function deleteQuestion(id){
  return axios.delete(`${config.URL_BASE}/faq/questions/${id}`, requestHeaders())
    .then(handleResponse);
}