import React from 'react';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
} from '@material-ui/core';
import { tableCss } from './TableCss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from '../../helpers';

function IdeaApproverTable() {
  const tableClasses = tableCss();
  const { ideaDetails } = useSelector(state => state.idea)
  const { t } = useTranslation();
  const columns = [
    { id: 'nome', label: t('nome') },
    { id: 'email', label: t('email') },
    { id: 'businessUnit', label: t('unidade_de_negocio') },
    { id: 'local', label: t('local') },
    { id: 'occupationArea', label: t('area_de_atuacao') },
  ];

  return (
    <Paper className={tableClasses.paperRoot}>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="users table"
          classes={{ root: tableClasses.table }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  classes={{ root: tableClasses.tableCellHeader }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            { 
              ideaDetails.approvers.map(item => {
                return (
                  <TableRow
                    hover
                    key={item._id}
                    style={{ cursor: 'pointer' }}
                    classes={{ root: tableClasses.tableRow }}
                  >
                    <TableCell 
                      classes={{ root: tableClasses.tableCellRadiusLeft }}
                      onClick={() => history.push(`/profile/${item._id}`)}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCell }}>
                      {item.email}
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCell }}>
                      {item.businessUnit}
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCell}}>
                      {item.local}
                    </TableCell>
                    <TableCell classes={{ root: tableClasses.tableCellRadiusRight }}>
                      {item.occupationArea}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export { IdeaApproverTable };

