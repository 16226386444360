import React, { useEffect } from 'react';
import { makeStyles, Card, CardContent, Typography,Avatar, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import { Skeleton } from '@material-ui/lab';
import { AvatarWithoutPhoto } from '../Shared/AvatarWithoutPhoto';

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		marginBottom: 24
	},
	contentContainer: {
		padding: 48,
		marginBottom: 33,
	},
	title: {
		textTransform: 'uppercase',
		color: theme.palette.neutrals.low.dark,
		fontSize: 14,
		fontWeight: 'bold',
	},
	rankIdeaName: {
		color: theme.palette.neutrals.low.medium,
		marginTop: 13,
		fontSize: 14
	},
	rankTitle: {
		color: theme.palette.neutrals.low.dark,
		fontSize: 16,
		fontWeight: 600,
		marginTop: 18,
		marginBottom: 10
	},
	rankUserName: {
		color: theme.palette.neutrals.low.medium,
		fontSize: 12,
		fontWeight: 600,
		marginLeft: 8
	},
	rankThumbNail: {
		width: 20,
		height: 20,
		borderRadius: '7rem'
	},

}));

function ChallengeRankCard({ challenge }) {
	const classes = useStyles();
	const { rewards } = challenge;
	const { winnerCard } = useSelector(state => state.challenge);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	
	const winners = winnerCard.filter(win => win !== null);

	useEffect(() => {
		rewards && rewards.forEach(({ index, win: { name, user } }) => dispatch(challengeActions.setWinnerCard(index, name, user)));
	}, [rewards]);


	return (
		<Card className={classes.cardContainer}>
			<CardContent className={classes.contentContainer}>
				<Typography className={classes.title}>{t('ideias_premiadas')}</Typography>
				<Box>
					{
						winners?.length === rewards?.length ? winners.map(({ position, ideaName, userName, url }) => (
							<Box key={position}>
								<Typography className={classes.rankTitle}>{position}º {t('lugar')}</Typography>
								<Typography className={classes.rankIdeaName}>{ideaName}</Typography>
								<Box display='flex'>
        {url ? (
          <Avatar
            alt={userName}
            src={url}
            className={classes.rankThumbNail}
          />
        ) : (
          <AvatarWithoutPhoto className={classes.rankThumbNail } ></AvatarWithoutPhoto>
        )}
									{/* <img src={url} alt={userName} className={classes.rankThumbNail}/> */}
									<Typography className={classes.rankUserName}>{userName}</Typography>
								</Box>
							</Box>
						)) : rewards.map((_reward, index) => (<Box key={index}>
								<Typography className={classes.rankTitle}>
									<Skeleton variant="text" animation="wave" />
								</Typography>
								<Typography className={classes.rankIdeaName}>
									<Skeleton variant="text" animation="wave" />
								</Typography>
								<Box display='flex'>
									<Skeleton width={20} height={20} variant="circle" animation="wave" />
									<Typography className={classes.rankUserName}>
										<Skeleton width={100} variant="text" animation="wave" />
									</Typography>
								</Box>
							</Box>))
					}
				</Box>
			</CardContent>
		</Card>
	);
};

export { ChallengeRankCard };
